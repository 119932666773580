@import '../../../../scss/theme-bootstrap';

.email-signup-panel {
  &__wrapper {
    display: flex;
    text-align: $ldirection;
  }
  &__content-wrapper {
    flex: auto;
  }
  &__image {
    @include swap_direction(margin, 0 20px 0 0);
    max-width: 50%;
  }
  &__title {
    padding: 0 0 20px;
    font-weight: 500;
    text-align: $ldirection;
  }
  &__copy {
    text-align: $ldirection;
  }
  &__fields-container {
    display: flex;
  }
  &__fields {
    flex-grow: 1;
    margin-#{$rdirection}: 5px;
    input[type="tel"],
    input[type="email"] {
      height: 40px;
      border-color: $color-navy;
      box-shadow: none;
      &.error {
        border-color: $color-red;
      }
    }
    .form-item {
      margin-bottom: 15px;
    }
  }
  &__field {
    width: 100%;
    margin: 0 auto;
  }
  &__button {
    text-align: center;
  }
  &__submit {
    margin: 0 auto 16px;
    width: 100%;
  }
  &__promotions-terms-conditions {
    margin-bottom: 30px;
    text-align: $ldirection;
    label p {
      margin-top: 0;
      text-transform: none;
      font-size: 14px;
      line-height: 1.2;
    }
  }
  &__information {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: none;
  }
  &__icon--information {
    float: $ldirection;
    width: 20px;
    height: 20px;
    margin-#{$rdirection}: 20px;
  }
  &__gdpr-overlay {
    display: none;
    position: absolute;
    text-decoration: none;
    padding: 15px;
    background-color: $color-black;
    color: $color-white;
    top: 40px;
    width: 100%;
    z-index: 2;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media (min-width: $large-min) {
      line-height: normal;
      #{$ldirection}: -73%;
      top: 0;
      width: 71%;
    }
    &.expanded {
      display: flex;
    }
  }
  &__gdpr-link {
    position: relative;
    margin-bottom: 1em;
  }
  &__gdpr-overlay-icon--close {
    fill: $color-white;
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
  }
  &__gdpr-overlay-close-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    order: 2;
  }
  &__gdpr-overlay-copy {
    order: 1;
  }
  &__gdpr-link-copy {
    &:hover ~ .email-signup-panel__gdpr-overlay {
      display: flex;
    }
  }
  &__learn-more {
    text-align: center;
    margin: 0 auto 20px;
  }
  &__message--success {
    color: $color-green;
  }
  &__message--error {
    color: $color-red;
  }
}
